@font-face {
  font-family: 'Pretendard-SemiBold';
  src: url('fonts/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'TossFaceFont';
  src: url('fonts/TossFaceFont.ttf') format('truetype');
}

* {
  font-family: Pretendard-SemiBold, TossFaceFont;
}

img {
  max-width: 100%;
}

.pt-5 {
  background-color: #2C2F33;
  color: white;
  text-align: center;
}

.footer_atag {
  text-decoration: none;
  color: #e0e0e0;
}

.footer_atag:hover {
  color: #999999;
}